/* AddtoCart.css */
.cart-container {
    margin-top: 20px;
  }
  
  .cart-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px;
  }
  
  .cart-item img {
    width: 80px;
    height: 80px;
    margin-right: 20px;
  }
  
  .cart-item-details {
    flex-grow: 1;
  }
  
  .cart-item-details p {
    margin: 0;
  }
  
  .cart-item-actions {
    margin-left: auto;
  }
  
  .cart-total {
    font-weight: bold;
    margin-top: 20px;
  }
  
