/* styles.css */

.fade-in {
    animation: fadeIn 0.5s;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* styles.css */

.custom-modal {
    max-width: 620px !important; /* Set your desired width */
    margin: auto; /* Center the modal horizontally */
  }
  
  /* Custom CSS */
.custom-modal-title {
    text-align: center !important;
  }
  