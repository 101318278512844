/* Checkout.css */

/* Custom styles for checkout page */

.section-py {
    padding-top: 50px;
  }
  
  .bg-lighter {
    background-color: #f8f9fa;
  }
  
  .btn-next {
    width: 100%;
  }
  
  .list-group-item {
    border: none;
    background-color: transparent;
  }
  
  /* Animation effects */
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animated {
    animation-duration: 0.5s;
    animation-fill-mode: both;
  }
  
  .fadeInUp {
    animation-name: fadeInUp;
  }
  
  .checkout-item {
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .checkout-item-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .btn-label-success {
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-label-success:hover {
    background-color: #218838;
    border-color: #1e7e34;
  }  

  .payment-option {
    display: inline-block;
    margin-right: 20px; /* Adjust margin as needed */
  }
  
  .payment-option input[type="radio"] {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
  
  .payment-option label {
    display: inline-block;
    vertical-align: middle;
  }
  

  .product-image {
    width: 100px; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
    transition: transform 0.3s ease; /* Smooth transition on hover */
  }
  
  .product-image:hover {
    transform: scale(1.1); /* Scale up on hover */
  }
  
  .product-name,
  .product-description,
  .product-quantity,
  .product-price,
  .product-category,
  .accessory-label {
    transition: opacity 0.3s ease; /* Smooth transition for opacity */
  }
  
  .list-group-item:hover .product-name,
  .list-group-item:hover .product-description,
  .list-group-item:hover .product-quantity,
  .list-group-item:hover .product-price,
  .list-group-item:hover .product-category,
  .list-group-item:hover .accessory-label {
    opacity: 0.8; /* Reduce opacity on hover */
  }
  
  .checkout-item {
    animation-duration: 0.5s;
  }
  
  .animate__fadeInUp {
    animation-name: fadeInUp;
  }
  
  .animate__slideInRight {
    animation-name: slideInRight;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideInRight {
    from {
      opacity: 0;
      transform: translateX(20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .modal-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px; /* Adjust the maximum width as needed */
    background-color: white; /* Semi-transparent background */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Add box shadow */
    z-index: 1000; /* Ensure the modals are displayed above other content */
    animation: fadeIn 0.3s ease forwards; /* Add fade-in animation */
  }
  
  .modal-content {
    padding: 20px;
    font-family: 'Arial', sans-serif; /* Change 'Arial' to your desired font */
  }
  
  .modal-title {
    margin-top: 0;
    font-family: 'Arial', sans-serif; /* Change 'Arial' to your desired font */
    color: black;
    align-self: center;
  }
  
  .modal-description {
    margin-bottom: 15px;
    font-family: 'Arial', sans-serif; /* Change 'Arial' to your desired font */
    font-style: italic;
    color: red;
    align-self: center;
  }
  
  .modal-list {
    padding-left: 20px;
    font-family: 'Arial', sans-serif; /* Change 'Arial' to your desired font */
  }
  
  .modal-checkbox {
    margin-top: 15px;
    font-family: 'Arial', sans-serif; /* Change 'Arial' to your desired font */
  }
  
  .modal-btn {
    margin-top: 20px;
    font-family: 'Arial', sans-serif; /* Change 'Arial' to your desired font */
  }
  

  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .back-button {
    margin-top: -25px;
    display: flex;
    align-items: center;
    background: none;
    color: rgb(104, 101, 182);
    border: none;
    cursor: pointer;
    font-size: 24px; /* Increase the size of the icon */
    transition: color 0.3s ease;
  }
  
  .back-button i {
    margin-right: 0;
  }
  
  .back-button:hover {
    color: black;
  }
  