.navbar-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
    transform: rotate(180deg); /* Rotate the SVG background 180 degrees (upside down) */
}

.navbar-nav .nav-link {
    color: black !important; /* Set font color to black */
    font-weight: bold; 
    font-family:Arial, Helvetica, sans-serif;
}

@keyframes slideIn {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .animation1 img {
    width: 250px; /* Set your desired width */
    height: 80px; /* Set your desired height or use auto for maintaining aspect ratio */
    animation: slideIn 0.5s ease forwards;
  }
  
  

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.animation2 img {
  animation: bounce 1.8s ease infinite;
}

/* .main-container {
    background-color: #274D62;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'%3E%3Cg %3E%3Ccircle fill='%23274D62' cx='50' cy='0' r='50'/%3E%3Cg fill='%23325569' %3E%3Ccircle cx='0' cy='50' r='50'/%3E%3Ccircle cx='100' cy='50' r='50'/%3E%3C/g%3E%3Ccircle fill='%233d5d70' cx='50' cy='100' r='50'/%3E%3Cg fill='%23486578' %3E%3Ccircle cx='0' cy='150' r='50'/%3E%3Ccircle cx='100' cy='150' r='50'/%3E%3C/g%3E%3Ccircle fill='%23526d7f' cx='50' cy='200' r='50'/%3E%3Cg fill='%235c7687' %3E%3Ccircle cx='0' cy='250' r='50'/%3E%3Ccircle cx='100' cy='250' r='50'/%3E%3C/g%3E%3Ccircle fill='%23677e8e' cx='50' cy='300' r='50'/%3E%3Cg fill='%23718796' %3E%3Ccircle cx='0' cy='350' r='50'/%3E%3Ccircle cx='100' cy='350' r='50'/%3E%3C/g%3E%3Ccircle fill='%237c909e' cx='50' cy='400' r='50'/%3E%3Cg fill='%238698a5' %3E%3Ccircle cx='0' cy='450' r='50'/%3E%3Ccircle cx='100' cy='450' r='50'/%3E%3C/g%3E%3Ccircle fill='%2391a1ad' cx='50' cy='500' r='50'/%3E%3Cg fill='%239baab5' %3E%3Ccircle cx='0' cy='550' r='50'/%3E%3Ccircle cx='100' cy='550' r='50'/%3E%3C/g%3E%3Ccircle fill='%23a6b3bd' cx='50' cy='600' r='50'/%3E%3Cg fill='%23b1bdc5' %3E%3Ccircle cx='0' cy='650' r='50'/%3E%3Ccircle cx='100' cy='650' r='50'/%3E%3C/g%3E%3Ccircle fill='%23bcc6cd' cx='50' cy='700' r='50'/%3E%3Cg fill='%23c7cfd5' %3E%3Ccircle cx='0' cy='750' r='50'/%3E%3Ccircle cx='100' cy='750' r='50'/%3E%3C/g%3E%3Ccircle fill='%23d2d9de' cx='50' cy='800' r='50'/%3E%3Cg fill='%23dde2e6' %3E%3Ccircle cx='0' cy='850' r='50'/%3E%3Ccircle cx='100' cy='850' r='50'/%3E%3C/g%3E%3Ccircle fill='%23e8ecee' cx='50' cy='900' r='50'/%3E%3Cg fill='%23f4f5f7' %3E%3Ccircle cx='0' cy='950' r='50'/%3E%3Ccircle cx='100' cy='950' r='50'/%3E%3C/g%3E%3Ccircle fill='%23FFFFFF' cx='50' cy='1000' r='50'/%3E%3C/g%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: contain;
  } */

.main-container {
  background: linear-gradient(180deg, #FFFFFF 0%, #f5d59a 100%);
}

.products-container {
  background: linear-gradient(180deg, #000000 0%, #000000 100%);
}

  .sticky-nav {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
  
  .gradient-text li {
    background: linear-gradient(to right, #ff416c, #ff4b2b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .company-taglines {
    background-image: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%); /* Gradient background */
    color: #fff; /* Text color */
    padding: 60px 0; /* Adjust padding as needed */
  }
  
  .container1 {
    text-align: center;
  }
  
  /* .taglines {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    color: #000000;
  }
  
  .tagline-card {
    background-color: rgba(255, 255, 255, 0.1); 
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease; 
  }
  
  .tagline-card:hover {
    transform: translateY(-5px); 
  }
  
  .tagline-card p {
    margin: 0;
    font-size: 18px;
    line-height: 1.5;
  } */

  .taglines {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap:20px;
    color: #000000;
    font-size: 18px;
  }

  .tagline-card {
    width: 300px;
    height: 100px;
    background-color: rgba(255, 255, 255, 0.1); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    opacity: 0; /* Initially hide the cards */
    transition: transform 0.3s ease; 
    animation: fadeIn 1s ease forwards; /* Apply animation */
  }

  .tagline-card:hover {
    transform: translateY(-5px); 
  }

  /* Keyframes for fadeIn animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .company-heading {
    position: relative;
    font-weight: bold;
    text-align: center;
    margin-bottom: -100px;
    overflow: hidden;
  }
  .company-heading span {
    display: inline-block;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    opacity: 0;
  }
  .company-heading span:first-child {
    color: black; /* Setting the color of "Welcome to" to black */
    animation-name: slideFromLeft;
  }
  .company-heading span:last-child {
    color: #ff7e67;
    animation-name: slideFromRight;
  }
  @keyframes slideFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slideFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  
  .navbar-wrapper {
    margin-bottom: 20px;
  }
  
  .tagline-wrapper {
    margin-top: 120px;
  }
  
  .btn-custom {
    background-color: darkorange;
    border: 1px solid darkorange;
    color: white; /* Text color */
    padding: 10px 20px; /* Adjust padding as needed */
    cursor: pointer; /* Optional: Change cursor on hover */
    border-radius: 20px;
}

.btn-custom:hover {
    background-color: #ff7f00; /* Darker shade on hover */
    border-color: #ff7f00;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInDown {
  from {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0);
  }
}

.language-flag {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px;
}

.language-flag img {
  width: 30px; /* Adjust flag size as needed */
  height: auto;
}

.language-flag.active {
  opacity: 0.8; /* Adjust opacity for active flag */
}

/* Adjust positioning as needed */
.navbar-nav {
  position: relative;
}

.navbar-nav .nav-item {
  margin-left: 10px; /* Adjust margin between items */
}

.custom-orange-btn {
  background-color: orange;
  border-color: orange;
  color: white;
}

.custom-orange-btn:hover {
  background-color: darkorange;
  border-color: darkorange;
  color: white; /* You might want to adjust this color if necessary */
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -40px, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.animate__animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animate__fadeInUp {
  animation-name: fadeInUp;
}

.animate__slideInDown {
  animation-name: slideInDown;
}

/* ----------------------------------------------------------------------------- */

@keyframes fadeInLeft {
  from {
    transform: translate3d(-40px, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.animate__fadeInLeft {
  animation-name: fadeInLeft;
}
@keyframes fadeInRight {
  from {
    transform: translate3d(40px, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.animate__fadeInRight {
  animation-name: fadeInRight;
}
@keyframes zoomIn {
  from {
    transform: scale3d(0.3, 0.3, 0.3);
    opacity: 0;
  }
  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

.animate__zoomIn {
  animation-name: zoomIn;
}
@keyframes rotateIn {
  from {
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}

.animate__rotateIn {
  animation-name: rotateIn;
}
@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.animate__bounceIn {
  animation-name: bounceIn;
}

.content.blurred {
  filter: blur(5px);
  pointer-events: none;
}
